<template>
    <div class="form_grp" v-if="isContact">
        <div class="group_item">
            <div class="field_wpr" :class="{ 'has-error': hasNumberError }">
                <input type="text" v-model="contactNumber" @focusout="validate(1)" />
            </div>
            <span class="text-danger" v-show="hasNumberError">{{ numberErrorMessage }}</span>
        </div>
    </div>
    <div class="form_grp">
        <div class="group_item">
            <div class="input_label">
                Send SMS
                <emoji-picker :onSelectEmoji="insertEmoji" classes="subject-emoji-picker" :ref="`emoji-${smsUuid}`" v-click-outside="hideEmoji" />
                <div class="dropdown dropdown-personalize mr-3" @click="smsOption = !smsOption" v-click-outside="hidePersonalize">
                    <i class="fas fa-user-alt"></i>
                    <div class="drp_wrapper" v-if="smsOption" :class="{ active : smsOption }">
                        <ul>
                            <li v-for="(personalize, p) of defaultPersonalize" @click="insertPersonalize(personalize)" :key="p">{{ personalize }}</li>
                        </ul>
                    </div>
                </div>
                <div class="dropdown dropdown-personalize" @click="msgOption = !msgOption" v-click-outside="hideOption">
                    <i class="fas fa-ellipsis-v"></i>
                    <div class="drp_wrapper" v-if="msgOption" :class="{active : msgOption}">
                        <ul>
                            <li @click="resetToDefault">Reset to default message</li>
                            <!-- <li @click="templateLibrary = !templateLibrary" :class="{ active : templateLibrary }">Import template</li> -->
                            <li @click="sendTestSMS = !sendTestSMS" :class="{active : sendTestSMS}">Send test SMS</li>
                            <li @click="previewTestSMS = !previewTestSMS" :class="{ active : previewTestSMS }">Preview in browser</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="field_wpr editor" :class="{ 'has-error': hasTextError }">
                <textarea :placeholder="placeholder" :id="smsUuid" :maxlength="maxLength" :name="name" v-model="smsText" @focusout="validate(2)"></textarea>
            </div>
            <span class="text-danger" v-show="hasTextError">{{ textErrorMessage }}</span>
            <span class="letter_count">
                <a class="import-template" @click="toggleTemplateDrawer()">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.43432 0.234312C7.74672 -0.078104 8.25328 -0.078104 8.56568 0.234312L11.7657 3.43431C12.0781 3.74674 12.0781 4.25326 11.7657 4.56569C11.4533 4.8781 10.9467 4.8781 10.6343 4.56569L8.8 2.73137V10.4C8.8 10.8418 8.44184 11.2 8 11.2C7.55816 11.2 7.2 10.8418 7.2 10.4V2.73137L5.36569 4.56569C5.05326 4.8781 4.54674 4.8781 4.23431 4.56569C3.9219 4.25326 3.9219 3.74674 4.23431 3.43431L7.43432 0.234312Z" fill="#999999"/>
                        <path d="M5.6 8.42745C5.6 8.02889 5.6 7.82969 5.5306 7.67409C5.45022 7.49393 5.3061 7.34977 5.12593 7.26945C4.97035 7.20001 4.7711 7.20001 4.37258 7.20001C3.83677 7.20001 3.39445 7.20001 3.03237 7.22473C2.65694 7.25033 2.30966 7.30513 1.97542 7.44361C1.19133 7.76841 0.568368 8.39137 0.243584 9.17545C0.105136 9.50969 0.0503042 9.85697 0.0246962 10.2324C-7.75158e-06 10.5945 -7.71963e-06 11.0367 2.80366e-07 11.5726V11.6275C-7.71963e-06 12.1632 -7.75158e-06 12.6055 0.0246962 12.9676C0.0503042 13.3431 0.105136 13.6903 0.243584 14.0246C0.568368 14.8087 1.19133 15.4316 1.97542 15.7564C2.30966 15.8949 2.65694 15.9497 3.03237 15.9753C3.39445 16 3.83674 16 4.37255 16H11.6274C12.1633 16 12.6055 16 12.9676 15.9753C13.343 15.9497 13.6903 15.8949 14.0246 15.7564C14.8086 15.4316 15.4316 14.8087 15.7564 14.0246C15.8949 13.6903 15.9497 13.3431 15.9753 12.9676C16 12.6055 16 12.1633 16 11.6275V11.5726C16 11.0367 16 10.5945 15.9753 10.2324C15.9497 9.85697 15.8949 9.50969 15.7564 9.17545C15.4316 8.39137 14.8086 7.76841 14.0246 7.44361C13.6903 7.30513 13.343 7.25033 12.9676 7.22473C12.6055 7.20001 12.1633 7.20001 11.6274 7.20001C11.2289 7.20001 11.0296 7.20001 10.8741 7.26945C10.6939 7.34977 10.5498 7.49393 10.4694 7.67409C10.4 7.82961 10.4 8.02889 10.4 8.42745V10.4C10.4 11.7255 9.32552 12.8 8 12.8C6.67448 12.8 5.6 11.7255 5.6 10.4V8.42745Z" fill="#999999"/>
                    </svg>
                    Import Template
                </a>
                {{ smsText.length }} / {{ maxLength }}
            </span>
            <send-email-sms-preview v-model="sendTestSMS" :is-preview="previewTestSMS" @closePreview="previewTestSMS = value" :post-params="form" module="sms" :type="2" />
        </div>
    </div>
    <template-library v-model="templateLibrary" :on-import="handleTemplateImport" type="sms" />
    <sms-media v-model="smsMedia" />

    <label for="smschedule" class="switch_option capsule_btn">
        <h5>Schedule SMS</h5>
        <input type="checkbox" :true-value="1" :false-value="0" v-model="smsSchedule" id="smschedule" hidden>
        <div><span></span></div>
    </label>
    <div v-if="smsSchedule" class="schedule_sms">
        <h3 class="sub_header">Schedule SMS</h3>
        <div class="edit_section">
            <schedule-tab v-model="schedule" ref="launchpad-sms-schedule" />
            <div class="action_wpr mt-2">
                <button type="button" :disabled="scheduleLoader" class="btn save_btn" @click="handleScheduleSMS">
                    <i class="fa fa-spinner fa-spin" v-if="scheduleLoader"></i> {{ scheduleLoader ? 'Scheduling' : 'Schedule' }}
                </button>
            </div>
        </div>
    </div>
    <import-template v-model="importTemplate" :handle-import="handleTemplateImport" template-type="sms" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { uuid } from 'vue-uuid'
    import { mapState, mapActions } from 'vuex'

    const ScheduleTab = defineAsyncComponent(() => import('@/components/ScheduleTab'))
    const TemplateLibrary = defineAsyncComponent(() => import('@/components/TemplateLibrary'))
    const SmsMedia = defineAsyncComponent(() => import('@/components/SmsMedia'))
    const SendEmailSmsPreview = defineAsyncComponent(() => import('@/components/SendEmailSmsPreview'))
    const EmojiPicker = defineAsyncComponent(() => import('@/components/EmojiPicker'))
    const ImportTemplate = defineAsyncComponent(() => import('@/components/ImportTemplate'))

    import Toastr from '@/utils/Toastr'
    import constants from '@/utils/Constant'

    export default {
        name: "Sms Component",

        data () {
            return {
                smsUuid: 'sms-'+uuid.v4(),
                phoneAdd: false,
                phone: false,
                smsOption: false,
                msgOption: false,
                templateLibrary: false,
                sendTestSMS: false,
                previewTestSMS: false,
                templateType: '',
                smsAttachment: '',
                smsSchedule: false,
                defaultPersonalize: [],
                schedule: {},
                smsMedia: {},
                hasTextError: false,
                hasNumberError: false,
                numberErrorMessage: '',
                textErrorMessage: '',
                contactNumber: '',
                form: {},
                defaultContent: '',
                importTemplate: false,
            };
        },

        props:{
            name: String,
            isContact: Boolean,
            modelValue: String,
            number: String,
            contacts: [String, Number],
            placeholder: {
                type: String,
                default: 'Type message',
            },
            personalize: {
                type: [Object, Array],
                default: () => [],
            },
            maxLength: {
                type: Number,
                default: 320,
            },
            source: {
                type: String,
                default: 'Launch Pad',
            },
            successHandler: Function,
            defaultSmsHandler: {
                type: Boolean,
                default: false,
            },
            handleDefaultSms: Function,
        },

        emits: ['update:modelValue', 'update:number'],

        watch: {
            number (number) {
                const vm = this;

                vm.contactNumber = number;
            },

            contactNumber (number) {
                const vm = this;

                vm.$emit('update:number', number);
            },

            sendTestSMS (val) {
                if (val) {
                    const vm = this;

                    vm.setSMSContent();
                }
            },

            previewTestSMS (val) {
                if (val) {
                    const vm = this;

                    vm.setSMSContent();
                }
            },
        },

        components:{
            ScheduleTab,
            TemplateLibrary,
            SmsMedia,
            SendEmailSmsPreview,
            EmojiPicker,
            ImportTemplate,
        },

        computed: {
            ...mapState({
                scheduleLoader: state => state.smsCenterModule.scheduleLoader,
            }),

            smsText: {
                get () {
                    return this.modelValue;
                },
                set (sms) {
                    this.$emit('update:modelValue', sms);
                }
            },
        },

        mounted () {
            const vm = this;

            vm.smsText            = vm.modelValue;
            vm.defaultContent     = vm.modelValue;
            vm.contactNumber      = vm.number;
            vm.defaultPersonalize = constants.personalize.concat(vm.personalize);
        },

        methods: {
            ...mapActions({
                sendSMS: 'contactModule/sendSMS',
                scheduleSMS: 'smsCenterModule/scheduleSMS',
            }),

            insertEmoji (emoji) {
                const vm = this;
                const textarea    = document.getElementById(vm.smsUuid);
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = emoji;

                vm.smsText = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            insertPersonalize (personalize) {
                const vm = this;
                const textarea    = document.getElementById(vm.smsUuid);
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = `{{ ${ personalize.toLowerCase().replaceAll(' ', '_') } }}`;

                vm.smsText = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            resetToDefault () {
                const vm = this;

                if (vm.defaultSmsHandler) {
                    vm.handleDefaultSms();
                } else if (vm.defaultContent) {
                    vm.smsText = vm.defaultContent;
                } else {
                    vm.smsText = '';
                }
            },

            handleTemplateImport (template) {
                const vm = this;

                vm.smsText = template.sms_content;

                if (template.sms_media) {
                    vm.smsMedia.is_default  = true;
                    vm.smsMedia.has_media   = 1;
                    vm.smsMedia.media       = [template.sms_media];
                } else {
                    vm.smsMedia.is_default  = false;
                    vm.smsMedia.has_media   = 0;
                    vm.smsMedia.media       = [];
                }

                setTimeout(function () {
                    vm.smsMedia.is_default  = false;
                }, 500);
            },

            validate (type = 0) {
                const vm    = this;
                let isValid = true;

                vm.hasNumberError     = false;
                vm.hasTextError       = false;
                vm.numberErrorMessage = '';
                vm.textErrorMessage   = '';

                if (!vm.contactNumber && vm.contactNumber != undefined && (type == 0 || type == 1)) {
                    isValid = false;

                    vm.hasNumberError     = true;
                    vm.numberErrorMessage = 'The contact number field is required.'
                }

                if (!vm.smsText  && (type == 0 || type == 2)) {
                    isValid = false;

                    vm.hasTextError     = true;
                    vm.textErrorMessage = 'The sms field is required.'
                } else if(vm.smsText.length > 320 && (type == 0 || type == 2)) {
                    isValid = false;

                    vm.hasTextError     = true;
                    vm.textErrorMessage = 'The sms character must be less than or equal to 320.'
                }

                return isValid;
            },

            handleScheduleSMS () {
                const vm = this;

                if (vm.validate()) {
                    const params = {
                        media: vm.smsMedia,
                        sms: vm.smsText,
                        contact_number: vm.contactNumber,
                        contact_id: vm.contacts,
                        source: vm.source,
                        delay: vm.schedule,
                    };

                    vm.scheduleSMS(params).then((result) => {
                        if (result && vm.successHandler) {
                            vm.successHandler();
                        }
                    });
                }
            },

            handleSMSSend () {
                const vm = this;

                if (vm.validate()) {
                    const params = {
                        media: vm.smsMedia.media,
                        sms: vm.smsText,
                        contact_number: vm.contactNumber,
                        contact_id: vm.contacts,
                        source: vm.source,
                    };

                    return vm.sendSMS(params).then((result) => {
                        if (result && vm.successHandler) {
                            vm.successHandler();
                        }
                    });
                }
            },

            handleBulkSMSSend () {
                const vm = this;

                if (vm.validate(2)) {
                    const params = {
                        media: vm.smsMedia.media,
                        sms: vm.smsText,
                        contact_number: vm.contactNumber,
                        contact_id: vm.contacts,
                        source: vm.source,
                    };

                    return vm.sendSMS(params).then((result) => {
                        if (result && vm.successHandler) {
                            vm.successHandler();
                        }
                    });
                }
            },

            setSMSContent () {
                const vm = this;

                if (vm.validate()) {
                    vm.form = {
                        media: vm.smsMedia.media,
                        content: vm.smsText,
                        contact_number: vm.contactNumber,
                        source: vm.source,
                    };
                }
            },

            hideEmoji () {
                const vm = this;
                const emoji = vm.$refs[`emoji-${vm.smsUuid}`];

                if (emoji && emoji.toggle) {
                    emoji.toggle = false;
                }
            },

            hidePersonalize () {
                const vm = this;

                vm.smsOption = false;
            },

            hideOption () {
                const vm = this;

                vm.msgOption = false;
            },

            toggleTemplateDrawer () {
                const vm = this;

                vm.importTemplate = true;
            },
        },
    }
</script>

<style scoped>
    :deep(.v3-input-emoji-picker) {
        margin: 0 0 0 auto;
    }

    :deep(.subject-emoji-picker .custom-emoji-picker) {
        top: 0;
    }

    :deep(.v3-input-picker-icon) {
        height: 0;
        margin-right: 5px;
    }

    :deep(.emoji-picker.subject-emoji-picker) {
        margin: 0 0 0 auto;
    }

    .input_label {
        position: relative;
    }

    :deep(.subject-emoji-picker) {
        position: absolute;
        right: 0;
    }

    :deep(.subject-emoji-picker .dropdown-toggle) {
        margin-right: 40px;
    }

    .dropdown-personalize {
        position: absolute;
        right: 0;
    }

    .letter_count a.import-template {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #0e101a;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .letter_count a.import-template svg {
        margin-right: 7px;
    }
    .field_wpr.editor{
        border-radius: 6px 6px 0 0;
    }
    .field_wpr textarea{
        font-size: 16px;
        line-height: 32px;
        font-weight: 400;
        color: #0e101a;
        min-height: 180px;
        resize: vertical;
    }
    .letter_count{
        background: #f9f9f9;
        padding: 10px;
        border: 1px solid #e9e9e9;
        border-top: 0;
        position: relative;
        margin-top: -1px;
        border-radius: 0 0 6px 6px;
    }
</style>
